<template>
  <div class="z-10 absolute top-5 left-5 flex flex-col">
    <div class="relative" v-if="company.role.key !== 'OBSERVER'">
      <button
        class="absolute top-0 z-10 bg-white h-9 w-9 rounded-full shadow focus_outline-none hover_bg-cancelbtnhover flex justify-center items-center transition-all duration-300"
        type="button"
        slot="reference"
        @click="isNavBar = !isNavBar"
      >
        <transition
          leave-active-class="transition duration-300"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            class="loader h-9 w-9 absolute inset-0"
            v-if="spinnerLoading"
          ></div>
        </transition>
        <SquareIcon />
      </button>
      <div
        class="absolute top-0 transition-all duration-300"
        :class="!spinnerLoading && isNavBar ? 'transformation' : 'opacity-0'"
      >
        <button
          class="bg-navbar hover_bg-headerText h-9 w-9 shadow rounded-full focus_outline-none flex justify-center items-center"
          type="button"
          slot="reference"
          @click="$emit('users')"
          @focus="isUsersLabel = true"
          @blur="isUsersLabel = false"
        >
          <UserIcon />
        </button>
        <span class="title">{{ $t('nav.users') }}</span>
      </div>
      <span>
        <div
          class="absolute top-0 transition-all duration-300"
          :class="!spinnerLoading && isNavBar ? 'transformation1' : 'opacity-0'"
        >
          <button
            class="bg-navbar hover_bg-headerText h-9 w-9 shadow rounded-full focus_outline-none flex justify-center items-center"
            type="button"
            slot="reference"
            @focus="isUnitsLabel = true"
            @blur="isUnitsLabel = false"
            @click="$emit('units')"
          >
            <CarIcon />
          </button>
          <span class="title">{{ $t('nav.objects') }}</span>
        </div>
        <div
          class="absolute top-0 transition-all duration-300"
          :class="!spinnerLoading && isNavBar ? 'transformation2' : 'opacity-0'"
        >
          <button
            class="bg-navbar hover_bg-headerText h-9 w-9 shadow rounded-full focus_outline-none flex justify-center items-center"
            @focus="isGeoLabel = true"
            @blur="isGeoLabel = false"
            type="button"
            slot="reference"
            @click="$emit('geozones')"
          >
            <GeozonesIcon />
          </button>
          <span class="title">{{ $t('nav.geozones') }}</span>
        </div>
        <div
          class="absolute top-0 transition-all duration-300"
          :class="!spinnerLoading && isNavBar ? 'transformation3' : 'opacity-0'"
        >
          <button
            class="bg-navbar hover_bg-headerText h-9 w-9 shadow rounded-full focus_outline-none flex justify-center items-center"
            type="button"
            slot="reference"
            @focus="isNotifyLabel = true"
            @blur="isNotifyLabel = false"
            @click="$emit('notifications')"
          >
            <BellIcon />
          </button>
          <span class="title">{{ $t('nav.notifications') }}</span>
        </div>
        <div
          class="absolute top-0 transition-all duration-300"
          :class="!spinnerLoading && isNavBar ? 'transformation4' : 'opacity-0'"
        >
          <button
            class="bg-navbar hover_bg-headerText h-9 w-9 shadow rounded-full focus_outline-none flex justify-center items-center"
            type="button"
            slot="reference"
            @focus="isRacingLabel = true"
            @blur="isRacingLabel = false"
            @click="$emit('race')"
          >
            <RouteIcon />
          </button>
          <span class="title">{{ $t('nav.trips') }}</span>
        </div>
        <div
          class="absolute top-0 transition-all duration-300"
          :class="!spinnerLoading && isNavBar ? 'transformation5' : 'opacity-0'"
        >
          <button
            class="bg-navbar hover_bg-headerText h-9 w-9 shadow rounded-full focus_outline-none flex justify-center items-center"
            type="button"
            slot="reference"
            @focus="isMailingLabel = true"
            @blur="isMailingLabel = false"
            @click="$emit('mailing')"
          >
            <MailIcon />
          </button>
          <span class="title">{{ $t('nav.mailing') }}</span>
        </div>
        <div
          class="absolute top-0 transition-all duration-300"
          :class="
            !spinnerLoading && isNavBar
              ? 'transformation6 translate-y-84'
              : 'opacity-0'
          "
        >
          <button
            class="bg-navbar hover_bg-headerText h-9 w-9 shadow rounded-full focus_outline-none flex justify-center items-center"
            type="button"
            @focus="isReportLabel = true"
            @blur="isReportLabel = false"
            slot="reference"
            @click="$emit('reports')"
          >
            <DeskIcon />
          </button>
          <span class="title">{{ $t('nav.reports') }}</span>
        </div>
        <div
          class="absolute top-0 transition-all duration-300"
          :class="
            !spinnerLoading && isNavBar
              ? 'transformation7 translate-y-96'
              : 'opacity-0'
          "
        >
          <button
            class="bg-navbar hover_bg-headerText h-9 w-9 shadow rounded-full focus_outline-none flex justify-center items-center"
            type="button"
            slot="reference"
            @focus="isMonitoringLabel = true"
            @blur="isMonitoringLabel = false"
            @click="$emit('monitoring')"
          >
            <MapPointIcon />
          </button>
          <span class="title">{{ $t('nav.monitoring') }}</span>
        </div>
      </span>
    </div>
    <div class="flex flex-col" v-else-if="company.role.key === 'OBSERVER'">
      <button
        class="absolute top-0 z-10 bg-white h-9 w-9 rounded-full shadow focus_outline-none hover_bg-cancelbtnhover flex justify-center items-center transition-all duration-300"
        type="button"
        slot="reference"
        @click="isNavBar = !isNavBar"
      >
        <transition
          leave-active-class="transition duration-300"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            class="loader h-9 w-9 absolute inset-0"
            v-if="spinnerLoading"
          ></div>
        </transition>
        <SquareIcon />
      </button>
      <div
        class="absolute top-0 transition-all duration-300"
        :class="!spinnerLoading && isNavBar ? '' : 'opacity-0'"
      >
        <button
          class="relative bg-navbar hover_bg-headerText h-9 w-9 shadow rounded-full focus_outline-none flex justify-center items-center mt-12"
          type="button"
          @focus="isReportLabel = true"
          @blur="isReportLabel = false"
          slot="reference"
          @click="$emit('reports')"
        >
          <DeskIcon />
          <span
            class="absolute bg-white text-someGrey font-semibold px-4 py-1 font-SourceSansPro rounded-sm text-sm transition duration-500"
            style="bottom: 14px; left: calc(100% - 10px)"
            :class="isReportLabel ? 'opacity-75' : 'opacity-0'"
            >{{ $t('nav.reports') }}</span
          >
        </button>
        <button
          class="relative bg-navbar hover_bg-headerText h-9 w-9 shadow rounded-full focus_outline-none flex justify-center items-center mt-3"
          type="button"
          slot="reference"
          @focus="isMonitoringLabel = true"
          @blur="isMonitoringLabel = false"
          @click="$emit('monitoring')"
        >
          <MapPointIcon />
        </button>
        <span
          class="absolute bg-white text-someGrey font-semibold px-4 py-1 font-SourceSansPro rounded-sm text-sm transition duration-500"
          style="bottom: 14px; left: calc(100% - 10px)"
          :class="isMonitoringLabel ? 'opacity-75' : 'opacity-0'"
          >{{ $t('nav.monitoring') }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SquareIcon from './assets/SquareIcon.vue'
import UserIcon from './assets/UserIcon.vue'
import CarIcon from './assets/CarIcon.vue'
import GeozonesIcon from './assets/GeozonesIcon.vue'
import BellIcon from './assets/BellIcon.vue'
import RouteIcon from './assets/RouteIcon.vue'
import MailIcon from './assets/MailIcon.vue'
import DeskIcon from './assets/DeskIcon.vue'
import MapPointIcon from './assets/MapPointIcon.vue'
import EquipmentIcon from './assets/EquipmentIcon.vue'

export default {
  components: {
    SquareIcon,
    UserIcon,
    CarIcon,
    GeozonesIcon,
    BellIcon,
    RouteIcon,
    MailIcon,
    DeskIcon,
    MapPointIcon,
    EquipmentIcon
  },
  props: {
    spinnerLoading: Boolean
  },
  data() {
    return {
      isNavBar: true,
      isUsersLabel: false,
      isGeoLabel: false,
      isReportLabel: false,
      isUnitsLabel: false,
      isNotifyLabel: false,
      isMonitoringLabel: false,
      isMailingLabel: false,
      isRacingLabel: false,
      isNavAdminLabel: false
    }
  },
  computed: {
    ...mapGetters('login', ['role']),
    ...mapState('login', {
      company: (state) => state.me
    })
  }
}
</script>

<style lang="stylus" scoped>
  .transformation{
    transform translateY(50px)
  }
  .transformation1{
    transform translateY(110px)
  }
  .transformation2{
    transform translateY(170px)
  }
  .transformation3{
    transform translateY(230px)
  }
  .transformation4{
    transform translateY(290px)
  }
  .transformation5{
    transform translateY(350px)
  }
  .transformation6{
    transform translateY(420px)
  }
  .transformation7{
    transform translateY(480px)
  }
  .title{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #4D6071;
  text-shadow: 0px 0px 7px #FFFFFF, 0px 0px 7px #FFFFFF, 1px -2px 7px #FFFFFF, 1px -2px 7px #FFFFFF, -2px 2px 7px #FFFFFF, -2px 2px 7px #FFFFFF, -2px 2px 7px #FFFFFF;
  }

.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.someclass {
  animation-duration: 0.5s;
  animation-name: slideup;
  animation-fill-mode: forwards;
}

.someclass1 {
  animation-duration: 0.5s;
  animation-name: slideup1;
  animation-fill-mode: forwards;
}

@keyframes slideup1 {
  100% {
    transform: translateY(0rem);
  }
}

@keyframes slideup {
  100% {
    transform: translateY(-3rem);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
